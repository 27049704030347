import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
const Contact = () => {
  return (
    <Layout>
      <Head
        title="Privacy and Copyright"
        descriptionContent="SilverGoldPills privacy and Copyright page."
      />
      <h1>Privacy Policy:</h1>
      <p>
        Back to the <Link to={"./"}>Home</Link> page.
      </p>
      <p>
        The privacy of our visitors to <b>SilverGoldPill.com</b> is important to
        us. This privacy statement has been created to show our commitment to
        the security and privacy of your personal information. This policy
        applies only to SilverGoldPill.com and the webpages found within this
        address. If you do not accept this privacy policy, you must not use this
        site (SilverGoldPill.com). Here is information on what types of personal
        information we receive and collect when you use and visit{" "}
        <b>SilverGoldPill.com</b>, and how we safeguard your information.
      </p>

      <h2>Log Files:</h2>
      <p>
        As with most other websites, we collect and use the data contained in
        log files. The information in the log files include your IP (internet
        protocol) address, your ISP (internet service provider, such as AOL or
        Shaw Cable), the browser you used to visit our site (such as Internet
        Explorer or Firefox), the time you visited our site and which pages you
        visited throughout our site.
      </p>

      <h2>Cookies & Web Beacons:</h2>
      <p>
        We do use cookies to store information, such as your personal
        preferences when you visit our site. For example, this could include
        only showing you a popup once in your visit.
      </p>
      <p>
        We also use third party advertisements on SilverGoldPill.com to support our
        site. Some of these advertisers may use technology such as cookies and
        web beacons when they advertise on our site, which will also send these
        advertisers (such as Google through the Google AdSense program)
        information including your IP address, your ISP, the browser you used to
        visit our site, and in some cases, whether you have Flash installed.
        This is generally used for geotargeting purposes (showing New York real
        estate ads to someone in New York, for example) or showing certain ads
        based on specific sites visited (such as showing cooking ads to someone
        who frequents cooking sites). If you would like more information about
        such practices, please click{" "}
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h3>Please Also Note that:</h3>
      <ul>
        <li>
          Google, as a third party vendor, uses cookies to serve ads on this
          site (SilverGoldPill.com).
        </li>
        <li>
          Google's use of the{" "}
          <a
            href="https://marketingplatform.google.com/about/enterprise/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DART
          </a>{" "}
          cookie enables it to serve ads to you based on your visit to the Site
          and other sites on the Internet.
        </li>
        <li>
          You may opt out of the use of the DART cookie by visiting the{" "}
          <a
            href="https://policies.google.com/technologies/ads"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google ad and content network privacy policy
          </a>
          .
        </li>
        <li>
          To see the latest list provided by Google, please visit:{" "}
          <a
            href="https://support.google.com/adsense/answer/94149"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Adsense Third Party Vendors
          </a>
        </li>
      </ul>
      <h2>Copyright Information</h2>
      <p>
        All the content, pictures, and handouts are the property of
        SilverGoldPill, and to be used only on SilverGoldPill.com website. You
        may not reproduce or distribute any of the websites contents or images
        without receiving prior written permission. The data and images on this
        website are subject to change without notice.
      </p>
      <p>
        SilverGoldPill.com makes no representations about the quality or content
        of the data or images. Furthermore, SilverGoldPill.com shall not be
        liable for any damages resulting from the use or misuse of any file in
        this website or from the data and images herein.
      </p>
      <p>
        It is up to you, the user, to take responsibility to take precautions to
        ensure that whichever web page you view is free from items of a
        destructive nature (viruses, malware, etc..)
      </p>
      <br />
    </Layout>
  )
}
export default Contact
